<template>
  <div>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon @click="$router.push({ path: '/inventory' })">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Inventory</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn  text color="teal" dark to="/inventory/purchaseOrder/list">Purchase Order</v-btn>
      <v-btn v-if="checkRightStatus(38)" text color="teal" dark to="/inventory/purchase/list">Purchase</v-btn>
      <v-btn text color="teal" dark to="/inventory/vendor/list"> Vendor</v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import purchseService from "@/modules/Inventory/Purchase/store.js";

export default {
  name: "inventory",
  mixins: [RegisterStoreModule],
  computed: {
    ...mapGetters("global", ["checkRightStatus"]),
  },
  created() {
    this.registerStoreModule("purchase", purchseService);
  }
};
</script>
